import './css/App.css';
import NavBar from './components/NavBar.js';
import Landing from './components/Landing.js';
import Services from './components/Services';
import OurClients from './components/OurClients';
import Footer from './components/Footer.js';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Landing />
      <Services id='services' />
      <OurClients id='ourClients'/>
      <Footer id='footer'/>
    </div>
  );
}

export default App;
