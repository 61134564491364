import React from 'react'

function Landing() {
  return (
    <div className='Lading-container'>
        <div className='landing-contents'>
            <h1>
                Over <span>20 MILLION</span> Players Reached!
            </h1>
            <h2>
                Roblox's best Marketing Company
            </h2>
            <button>
                Get in Touch
            </button>
        </div>
        <img src={require('../Assets/hero-illustration.png')} className='first-accent'/>
    </div>
  )
}

export default Landing