import React, { useRef } from 'react';
import { ReactComponent as Logo } from '../Assets/logo.svg';
import { ReactComponent as Discord } from '../Assets/discord.svg';
import { ReactComponent as Twitter } from '../Assets/twitter.svg';


function NavBar() {

    const docHeight = document.documentElement.scrollHeight

    let about = 0.20*docHeight
    let clients = 0.40*docHeight
    let Contact = 0.6*docHeight

    const scrollToHome = () => {
        window.scrollTo(0, 0); // Scrolls to 300 pixels down from the top of the page
    };
    const scrollToAbout = () => {
        window.scrollTo(0, about); // Scrolls to 300 pixels down from the top of the page
    };
    const scrollToClients = () => {
        window.scrollTo(0, clients); // Scrolls to 300 pixels down from the top of the page
    };
    const scrollToContact = () => {
        window.scrollTo(0, Contact); // Scrolls to 300 pixels down from the top of the page
    };

    const twitterRedirect = () => {
        window.location.href = 'https://twitter.com/RoGrowth'
    }

    return (
        <div className='navbar-container' style={{ overflow: 'auto' }}>
            <div>
                <Logo className='navbar-logo' />
            </div>
            <div className='navbar-a-container'>
                <a onClick={scrollToHome}>
                    Home
                </a>
                <a onClick={scrollToAbout}>
                    About
                </a>
                <a onClick={scrollToClients}>
                    Our Clients
                </a>
                <a onClick={scrollToContact}>
                    Contact
                </a>
                <div className='navbar-socials-container'>
                    <button>
                        <Discord />
                    </button>
                    <button onClick={twitterRedirect}>
                        <Twitter />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NavBar;

