import React from 'react'
import { useState } from 'react'
import { ReactComponent as Rocket } from '../Assets/marketing-icon.svg'

function Services() {
	const [page, setPage] = useState(0)

	const titles = ['TikTok Account Marketing', 'Content Creator Management']
	const descriptions = ['Have a TikTok account dedicated to your game, studio, or even your brand! We have grown multiple accounts, and gained over 500k followers! We will create the account, create the videos, and upload them without you having to do a thing! Enjoy organic marketing with a successful team!', 'Looking for a quick social media boost? This service is the PERFECT choice! Choose from our list of over 20 POPULAR content creators, and have them make videos promoting your game/brand! These creators specialize in promoting, and have MILLIONS of views in successful campaigns!']

	const handleClick = () => {
		page == 0 ? setPage(1) : setPage(0)
	}

	return (
		<div className='services-container'>
			<h1 className='OS'>
				Our Services
			</h1>
			<div className='os-services-container'>
				<button onClick={handleClick} className='arrow-button'>
					<i class="arrow left" ></i>

				</button>
				<div>
					<div className='SMM-container'>
						<Rocket />
						<div className='SMM-headers-container'>
							<h1>
								{titles[page]}
							</h1>
							<h3>
								{descriptions[page]}
							</h3>
						</div>
					</div>
				</div>
				<button onClick={handleClick} className='arrow-button'>
					<i class="arrow right" ></i>

				</button>
			</div>


		</div>
	)
}

export default Services