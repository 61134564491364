import React from 'react'
import { ReactComponent as Star } from '../Assets/star.svg'

function OurClients() {
    return (
        <div className='our-clients-container'>
            <h1>
                Our Clients
            </h1>
            <div className='star-container'>
                <Star className='star' />
                <Star className='star' />
                <Star className='star' />
                <Star className='star' />
                <Star className='star' />
            </div>
            <div className='clients-container'>

                <a href='https://www.roblox.com/games/13334548245/DESC-The-Russian-Sleep-Experiment'>
                    <div className='client-container TRSE'>

                        {/* <div>
                      <h1>
                          The Russian Sleep Experiment
                      </h1>
                      <h2>
                          By The Sleep Experiment Group
                      </h2>
                  </div>

                  <div className='client-row'>
                      <a className='client-row-a' href='https://www.roblox.com/games/13334548245/DESC-The-Russian-Sleep-Experiment'>
                          <button href>
                              Play
                          </button>
                      </a>
                      
                      <div className='client-numberss'>
                          <div className='client-numbers'>
                              <h1>
                                  30,000+
                              </h1>
                              <h2>
                                  Favourites
                              </h2>
                          </div>
                          <div className='client-numbers'>
                              <h1>
                                  1,600,000+
                              </h1>
                              <h2>
                                  Visits
                              </h2>
                          </div>
                          <div className='client-numbers'>
                              <h1>
                                  5,000+
                              </h1>
                              <h2>
                                  Likes
                              </h2>
                          </div>
                      </div>

                  </div> */}
                    </div>
                </a>

                <a href='https://www.roblox.com/games/11156779721/Volcano-The-Survival-Game'>
                    <div className='client-container TSG'>
                        {/* <div>
                        <h1>
                            The Survival Game
                        </h1>
                        <h2>
                            By Simple Games Incorporated
                        </h2>
                    </div>

                    <div className='client-row'>
                        <a className='client-row-a' href='https://www.roblox.com/games/11156779721/Volcano-The-Survival-Game'>
                            <button href>
                                Play
                            </button>
                        </a>
                        <div className='client-numberss'>
                            <div className='client-numbers'>
                                <h1>
                                    400,000+
                                </h1>
                                <h2>
                                    Favourites
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    97,000,000+
                                </h1>
                                <h2>
                                    Visits
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    130,000+
                                </h1>
                                <h2>
                                    Likes
                                </h2>
                            </div>
                        </div>

                    </div> */}
                    </div>
                </a>

                <a href='https://www.roblox.com/games/11599913094/UNFUSE-Nuke-Simulator'>
                    <div className='client-container NS'>
                        {/* <div>
                        <h1>
                            Nuke Simulator!
                        </h1>
                        <h2>
                            By Big BOOM Games
                        </h2>
                    </div>

                    <div className='client-row'>
                        <a className='client-row-a' href='https://www.roblox.com/games/11599913094/UNFUSE-Nuke-Simulator'>
                            <button href>
                                Play
                            </button>
                        </a>
                        <div className='client-numberss'>
                            <div className='client-numbers'>
                                <h1>
                                    90,000+
                                </h1>
                                <h2>
                                    Favourites
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    31,000,000+
                                </h1>
                                <h2>
                                    Visits
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    54,000+
                                </h1>
                                <h2>
                                    Likes
                                </h2>
                            </div>
                        </div>

                    </div> */}
                    </div>
                </a>

                <a href='https://www.roblox.com/games/11827760850/TRADE-ST-Pet-Capsules-Simulator'>
                    <div className='client-container PCS'>
                        {/* <div>
                        <h1>
                            Pet Capsules Simulator
                        </h1>
                        <h2>
                            By FutureWeb Games
                        </h2>
                    </div>

                    <div className='client-row'>
                        <a className='client-row-a' href='https://www.roblox.com/games/11827760850/TRADE-ST-Pet-Capsules-Simulator'>
                            <button href>
                                Play
                            </button>
                        </a>
                        <div className='client-numberss'>
                            <div className='client-numbers'>
                                <h1>
                                    16,000+
                                </h1>
                                <h2>
                                    Favourites
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    6,500,000+
                                </h1>
                                <h2>
                                    Visits
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    12,000+
                                </h1>
                                <h2>
                                    Likes
                                </h2>
                            </div>
                        </div>

                    </div> */}
                    </div>
                </a>

                <a href='https://www.roblox.com/games/9664474819/Burger-Game'>
                    <div className='client-container BG'>
                        {/* <div>
                        <h1>
                            Burger Game
                        </h1>
                        <h2>
                            By Method Men Studios

                        </h2>
                    </div>

                    <div className='client-row'>
                        <a className='client-row-a' href='https://www.roblox.com/games/9664474819/Burger-Game'>
                            <button href>
                                Play
                            </button>
                        </a>
                        <div className='client-numberss'>
                            <div className='client-numbers'>
                                <h1>
                                    210,000+
                                </h1>
                                <h2>
                                    Favourites
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    29,200,000+
                                </h1>
                                <h2>
                                    Visits
                                </h2>
                            </div>
                            <div className='client-numbers'>
                                <h1>
                                    54,000+
                                </h1>
                                <h2>
                                    Likes
                                </h2>
                            </div>
                        </div>

                    </div> */}
                    </div>
                </a>


            </div>
        </div>
    )
}

export default OurClients