import React from 'react'
import { ReactComponent as Email } from '../Assets/email.svg'
import { ReactComponent as Phone } from '../Assets/phone.svg'
import { ReactComponent as Logo } from '../Assets/logo.svg'
import { ReactComponent as Globe } from '../Assets/globe.svg'
function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-contact-container'>
                <div className='footer-contact-headers'>
                    <h2>Contact Us</h2>
                    <h1>Take The First Step In Making Your game Viral</h1>
                    {/* <div>
                    <Email/>
                    <h3>hello@rogrowth.com</h3>
                </div>
                <div>
                    <Phone />
                    4255 8234 5635
                </div> */}
                </div>
                <div className='footer-contact-fields'>
                    <input className='input1' placeholder='Name' />
                    <input className='input1' placeholder='email' />
                    <textarea className='input2' placeholder='Message' cols='30' rows='10' />
                    <button>Get In Touch</button>
                </div>

            </div>
            <div className='footer-images'>
                <a href='https://www.roblox.com/games/13334548245/DESC-The-Russian-Sleep-Experiment'>
                    <img src={require('../Assets/img1.png')} className='footer-image' />
                </a>
                <a href='https://www.roblox.com/games/11156779721/Volcano-The-Survival-Game'>
                    <img src={require('../Assets/img2.png')}
                    className='footer-image' />
                </a>
                <a href='https://www.roblox.com/games/11599913094/UNFUSE-Nuke-Simulator'>
                    <img src={require('../Assets/img3.png')}
                    className='footer-image' />
                </a>
                <a href='https://www.roblox.com/games/11827760850/TRADE-ST-Pet-Capsules-Simulator'>
                    <img src={require('../Assets/img4.png')}
                    className='footer-image' />
                </a>
                <a href='https://www.roblox.com/games/9664474819/Burger-Game'>
                    <img src={require('../Assets/img5.png')}
                    className='footer-image' />
                </a>
                
            </div>
            <div className='footer-footer-container'>
                <div className='footer-description'>
                    <Logo />
                    <h1>
                    Founded by Chrollo<br/>
                    </h1>
                    <h5>
                    Rogrowth LLC©2022
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default Footer